<template>
  <NuxtI18nLink
    :to="link"
    class="provider-card"
    :class="platform"
    data-t="provider-card"
    :data-provider-id="provider.id"
  >
    <StCounter
      class="provider-counter"
      bg-color="black"
      color="white"
      data-t="provider-counter"
      :label="provider.gamesNumber"
      :size="isMobile ? 'xs' : 's'"
    />
    <StIcon
      v-if="provider.iconLink"
      :icon-link="provider.iconLink"
      class="provider-icon"
      data-t="provider-icon"
    />
    <div v-else class="provider-name" data-t="provider-name-5p39">
      {{ provider.name }}
    </div>
  </NuxtI18nLink>
</template>

<script lang="ts" setup>
import type { Provider } from '../../types'

const { provider } = defineProps<{ provider: Provider }>()
const { platform, isMobile } = usePlatform()
const link = computed(() => `/casino/providers/${provider.code}`)
</script>

<style scoped>
.provider-card {
  all: unset;

  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  height: 80px;
  padding: var(--spacing-075);

  background: var(--background-primary);
  border-radius: var(--border-radius-150);

  transition: background-color 0.3s ease;

  &:hover {
    background: var(--background-secondary);
  }
}

.provider-counter {
  position: absolute;
  top: var(--spacing-075);
  left: var(--spacing-075);
}

.provider-icon {
  width: 180px;
  height: 72px;
}

.provider-name {
  font: var(--desktop-text-md-medium);
}

.mobile {
  &.provider-card {
    width: var(--mobile-width, 140px);
    height: 60px;
  }

  .provider-icon {
    width: 120px;
    height: 48px;
  }

  .provider-name {
    font: var(--mobile-caption-1-medium);
  }
}
</style>
